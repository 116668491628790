<template>
  <app-module-view>
    <template slot="body">
      <app-video-management-filter>
      </app-video-management-filter>
      <template v-for="contentBlock in contentBlocks">
        <app-content-block-timeline
          v-if="showContentBlockTimeline(contentBlock)"
          :key="contentBlock.id"
          type="video"
          :content-block="contentBlock"
          :required-permissions="requiredPermissions"
          ref="timelineComponents"
        >
        </app-content-block-timeline>
      </template>
      <div
        class="row"
      >
        <h3 class="col-lg-12">{{ $t('videoStats.videoplays') }}</h3>
        <template v-for="contentBlock in contentBlocks">
          <app-playlist-chart
            :contentBlock="contentBlock"
            :key="contentBlock.id"
          >
          </app-playlist-chart>
        </template>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import { mapState } from 'vuex'
import ModuleView from '@/components/ModuleView'
import { CONTENT_BLOCK_TYPE_VIDEO } from '@/components/mixins/valueObject/ContentBlockTypeMixin'
import ContentBlockTimeline from '@/components/contentBlockItem/timeline/Timeline'
import VideoManagementFilter from '../../components/video/videoManagement/VideoManagementFilter'
import PlaylistChart from '../../components/video/videoManagement/PlaylistChart'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'VideoManagementView',
  data () {
    return {
      hpTimeStatusInterval: 0,
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.VIDEO_MANAGEMENT_PERMISSIONS
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    contentBlocks () {
      const contentBlocks = this.$store.getters['contentBlock/list']
      return contentBlocks.filter(contentBlock => contentBlock.type === CONTENT_BLOCK_TYPE_VIDEO)
    },
    filter () {
      return this.$store.getters['contentBlock/hpManagementFilter']
    }
  },
  components: {
    appModuleView: ModuleView,
    appVideoManagementFilter: VideoManagementFilter,
    appContentBlockTimeline: ContentBlockTimeline,
    appPlaylistChart: PlaylistChart
  },
  methods: {
    updateTimelines () {
      const timelineComponents = this.$refs.timelineComponents
      timelineComponents.forEach(timeline => timeline.updateData())
    },
    showContentBlockTimeline (contentBlock) {
      return contentBlock.type === CONTENT_BLOCK_TYPE_VIDEO
    },
    init () {
      this.getContentBlocks()
    },
    getContentBlocks () {
      this.$store.commit('contentBlock/setLimit', 30)
      this.$store.commit('contentBlock/setHpManagementFilter', this.filter)
      this.$store.dispatch('contentBlock/fetchForHpManagement')
    }
  },
  created () {
    this.init()
  },
  mounted () {
    clearInterval(this.hpTimeStatusInterval)
    this.hpTimeStatusInterval = setInterval(() => {
      this.updateTimelines()
    }, 10000)
  }
}
</script>
